/* eslint-disable no-lone-blocks */
import React from "react";
import Card from "components/card";
import { CSVLink } from "react-csv";
import { userStaticsHeader } from "Constant/Constant";
import UserTransactionTenStackTable from "./userTransactionTenStackTable";

function RecentTransactions(props: { tableData: any; setTransactionButtons: any; transactionButtons: any; setCashAndOnlinePayments: any; cashAndOnlinePayments: any }) {

  const { tableData, transactionButtons, setTransactionButtons, setCashAndOnlinePayments, cashAndOnlinePayments } = props;

  const tableDataRemoveTopups = tableData.filter((e: any) => e.machine_code !== "0000");

  //cash and online payment butterfly
  const cash = tableData && tableData.filter((e: any) => e.msisdn === "99999");
  const online = tableData && tableData.filter((e: any) => e.msisdn !== "99999");

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {`Recent Transactions (${transactionButtons === "oil" ? tableDataRemoveTopups.length : tableData.length})`}

        </div>
        <div>
          <CSVLink data={transactionButtons === "oil" ? tableDataRemoveTopups : tableData} headers={userStaticsHeader} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink>
        </div>
      </header>
      <div>
        <button className={`rounded-full ${transactionButtons === "butterfly" ? "bg-teal-600" : "bg-white "}  px-5 py-2 mr-2 text-sm font-semibold ${transactionButtons === "butterfly" ? "text-white" : "text-black dark:text-gray-900"} shadow-sm ring-1 ring-inset ring-teal-600 `} onClick={() => setTransactionButtons("butterfly")}>Butterfly</button>
        <button className={`rounded-full ${transactionButtons === "oil" ? "bg-teal-600" : "bg-white"}  px-5 py-2 mr-2 text-sm font-semibold ${transactionButtons === "oil" ? "text-white" : "text-black dark:text-gray-900"} shadow-sm ring-1 ring-inset ring-teal-600 `} onClick={() => setTransactionButtons("oil")}>Oil</button>
        <button className={`rounded-full ${transactionButtons === "topup" ? "bg-teal-600" : "bg-white"}  px-5 py-2 mr-2 text-sm font-semibold ${transactionButtons === "topup" ? "text-white" : "text-black dark:text-gray-900"} shadow-sm ring-1 ring-inset ring-teal-600 `} onClick={() => setTransactionButtons("topup")}>User Topup</button>
      </div>

      {
        transactionButtons === "butterfly" ?
          <div className="mt-2">
            <button className={`rounded-full ${cashAndOnlinePayments === "online" ? "bg-teal-600" : "bg-white"}  px-5 py-2 mr-2 text-sm font-semibold ${cashAndOnlinePayments === "online" ? "text-white" : "text-black dark:text-gray-900"} shadow-sm ring-1 ring-inset ring-teal-600 `} onClick={() => setCashAndOnlinePayments("online")}>Online payments ({online.length})</button>
            <button className={`rounded-full ${cashAndOnlinePayments === "cash" ? "bg-teal-600" : "bg-white "}  px-5 py-2 mr-2 text-sm font-semibold ${cashAndOnlinePayments === "cash" ? "text-white" : "text-black dark:text-gray-900"} shadow-sm ring-1 ring-inset ring-teal-600 `} onClick={() => setCashAndOnlinePayments("cash")}>Cash payments ({cash.length})</button>
          </div>
          : null
      }
      <UserTransactionTenStackTable
        tableData={transactionButtons === "oil" ? tableDataRemoveTopups :
          transactionButtons === "butterfly" && cashAndOnlinePayments === "online" ?
            online : transactionButtons === "butterfly" && cashAndOnlinePayments === "cash" ?
              cash : tableData} />
    </Card>
  );
}

export default RecentTransactions;
