import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import routes from "routes";
import { IBEX, MERIT_PACKAGING, NATIONAL_FOODS, SUPER_ADMIN, decryptObject } from "Constant/Constant";

export default function Admin(props: { [x: string]: any }) {
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

  const { ...rest } = props;
  const location = useLocation();

  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const [open, setOpen] = useState(() => {
    return window.innerWidth < 1200 ? false : true;
  });

  useEffect(() => {
    const handleResize = () => {
      // Update the state based on window width when resized
      setOpen(window.innerWidth < 1200 ? false : true);
    };

    // Add event listener for resize event
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  //navbar
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const userRole = localStorageData?.user?.user_role;
  const firstName = localStorageData?.user?.first_name;


  //here you can hide the route which cant be access to any user
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && localStorageData) {
        if (prop.path === "topup" && localStorageData.user.company_code !== "0") {
          return null; // Hide the Topup, users and corportate clients route if company code is not 0
        } else if (prop.path === "users" && localStorageData.user.company_code !== "0") {
          return null; // Hide the "Users" route if company code is not 0
        } else if (prop.path === "clients" && localStorageData.user.company_code !== "0") {
          return null; // Hide the "Users" route if company code is not 0
        } else if (prop.path === "create-roles" && (localStorageData.user.user_role === "admin" || localStorageData.user.user_role === "ops")) {
          return null 
        } else if ((prop.path === "user-national-foods" || prop.path === "merit-packaging-users" || prop.path === "topup-nfl") && (localStorageData.user.user_role === "admin" || localStorageData.user.user_role === "ops" || localStorageData.user.user_role === SUPER_ADMIN)) {
          return null
        } else if ((prop.path === "points-share" || prop.path === "send-notifications" || prop.path === "topup" || prop.path === "create-roles" ) && localStorageData.user.user_role === "ops") {
          return null;
        } else if ((userRole === "company" && firstName === IBEX) && (prop.path === "points-share" || prop.path === "create-roles" || prop.path === "users" || prop.path === "topup" || prop.path === "send-notifications" || prop.path === "locations" || prop.path === "product-details" || prop.path === "user-national-foods" || prop.path === "merit-packaging-users" || prop.path === "clients" || prop.path === "topup-nfl")) {
          return null;
        } else if ((userRole === "company" && firstName === NATIONAL_FOODS) && (prop.path === "points-share" || prop.path === "create-roles" || prop.path === "users" || prop.path === "topup" || prop.path === "send-notifications" || prop.path === "locations" || prop.path === "product-details" || prop.path === "merit-packaging-users" || prop.path === "clients")) {
          return null;
        } else if ((userRole === "company" && firstName === MERIT_PACKAGING) && (prop.path === "points-share" || prop.path === "create-roles" || prop.path === "users" || prop.path === "topup" || prop.path === "send-notifications" || prop.path === "locations" || prop.path === "product-details" || prop.path === "user-national-foods" || prop.path === "clients" || prop.path === "topup-nfl")) {
          return null;
        } else {
          return (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />)
        }
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  const filteredRoutes = routes.filter((route) => {
    //sidebar hiding condition
    if ((userRole === "admin" || userRole === "ops" || userRole === "company") && (route.path === "create-roles")) {
      return false;
    }

    if (userRole === "ops" && (route.path === "topup-nfl" || route.path === "points-share" || route.path === "send-notifications" || route.path === "topup" || route.path === "user-national-foods" || route.path === "merit-packaging-users" || route.path === "user-national-foods")) {
      return false;
    }

    if (userRole === SUPER_ADMIN && (route.path === "topup-nfl" || route.path === "user-national-foods" || route.path === "merit-packaging-users")) {
      return false;
    }

    if (userRole === "admin" && (route.path === "topup-nfl" || route.path === "user-national-foods" || route.path === "merit-packaging-users")) {
      return false;
    }

    if ((userRole === "company" && firstName === IBEX) && (route.path === "topup-nfl" || route.path === "points-share" || route.path === "send-notifications" || route.path === "topup" || route.path === "users" || route.path === "clients" || route.path === "locations" || route.path === "product-details" || route.path === "user-national-foods" || route.path === "merit-packaging-users")) {
      return false;
    }

    if ((userRole === "company" && firstName === NATIONAL_FOODS) && (route.path === "points-share" || route.path === "send-notifications" || route.path === "topup" || route.path === "users" || route.path === "clients" || route.path === "locations" || route.path === "product-details" || route.path === "product-details"  || route.path === "merit-packaging-users")) {
      return false;
    }

    if ((userRole === "company" && firstName === MERIT_PACKAGING) && (route.path === "topup-nfl" || route.path === "points-share" || route.path === "send-notifications" || route.path === "topup" || route.path === "users" || route.path === "clients" || route.path === "locations" || route.path === "product-details" || route.path === "user-national-foods")) {
      return false;
    }

    return true; // Show all routes for company_code === "0"
  });

  return (
    <div className="flex h-full w-full">
      <Sidebar
        open={open}
        onClose={() => setOpen(false)}
        routes={filteredRoutes.filter(
          (route) =>
            route.path !== "machine-details/:id/:type" &&
            route.path !== "location-details/:id" &&
            route.path !== "privacy-policy" &&
            route.path !== "company-info"
        )}
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/home" replace />}
                />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
